interface ErrorMessages {
  [key: string]: string;
}

const messages: ErrorMessages = {
  'Order already exists': 'Ordem de preceptoria existente.',
  'Authentication failed': 'Falha na Autenticação',
  Default: 'Requisição inválida, verifique os dados enviados.',
};

export default messages;
