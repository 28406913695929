import React, { useState, useCallback, useRef, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import moment from 'moment';

import { Delete, Add, SupervisorAccount, Edit } from '@material-ui/icons';

import {
  Button,
  Typography,
  IconButton,
  Avatar,
  CardActions,
  CardContent,
  CardHeader,
  Card,
  Switch,
  Tooltip,
} from '@material-ui/core';

import { green } from '@material-ui/core/colors';

import { Container, useStyles } from './styles';

import getValidationErrors from '../../utils/getValidationErrors';

import api from '../../services/api';
import Alert from '../../components/Alert';
import Input from '../../components/Input';
import DialogForm from '../../components/DialogForm';
import { useAuth } from '../../hooks/Auth';
import { AlertProps } from '../../utils/dtos';
import {
  CreateCoordinatorData,
  Coordinator,
  UpdateCoordinatorData,
} from './dtos';

const Coordinators: React.FC = () => {
  const classes = useStyles();
  const { token } = useAuth();
  const formRefCreate = useRef<FormHandles>(null);
  const formRefUpdate = useRef<FormHandles>(null);

  const [open, setOpen] = React.useState(false);
  const [coordinators, setCoordinators] = useState<Coordinator[]>([]);
  const [
    coordinatorToUpdate,
    setCoordinatorToUpdate,
  ] = useState<Coordinator | null>(null);
  const [alert, setAlert] = useState<AlertProps>({
    isActive: false,
  });

  const Authorization = `Bearer ${token}`;

  useEffect(() => {
    async function getCoordinators() {
      try {
        const coordinatorsResponse = await api.get('/coordinators', {
          headers: {
            Authorization,
          },
        });

        setCoordinators(coordinatorsResponse.data);

        setAlert({
          isActive: true,
          type: 'success',
          message: 'Coordenadores listados com sucesso.',
        });
      } catch (error) {
        if (error.message.includes('401')) {
          setAlert({
            isActive: true,
            type: 'warning',
            message: 'Sessão expirada, faça login novamente.',
          });
        } else {
          setAlert({
            isActive: true,
            type: 'error',
            message: 'Erro ao listar coordenadores.',
          });
        }
      }
    }

    getCoordinators();
  }, []);

  const handleDelete = useCallback(
    async (coordinatorId) => {
      try {
        await api.delete(`/coordinators/${coordinatorId}`, {
          headers: {
            Authorization,
          },
        });

        setAlert({
          isActive: true,
          type: 'success',
          message: 'Coordenador deletado com sucesso.',
        });

        if (coordinators?.length) {
          const newCoordinators = coordinators.filter(
            (coordinator) => coordinator._id !== coordinatorId,
          );

          setCoordinators(newCoordinators);
        }
      } catch (error) {
        setAlert({
          isActive: true,
          type: 'error',
          message: 'Erro ao deletar coordenador.',
        });
      }
    },
    [coordinators],
  );
  const handleOpenUpdate = useCallback(
    (coordinatorId) => {
      const coordIdx = coordinators.findIndex((c) => c._id === coordinatorId);
      setCoordinatorToUpdate(coordinators[coordIdx]);
    },
    [coordinators],
  );

  const handleClose = useCallback(() => {
    setOpen(false);
    setCoordinatorToUpdate(null);
  }, []);

  const handleActivate = useCallback(
    async (coordinatorId) => {
      try {
        await api.get(`/coordinators/${coordinatorId}/activateDeactivate`, {
          headers: {
            Authorization,
          },
        });

        const currentCoords = coordinators;
        const coordIdx = currentCoords.findIndex(
          (c) => c._id === coordinatorId,
        );
        currentCoords[coordIdx].isActive = !currentCoords[coordIdx].isActive;

        setCoordinators([...currentCoords]);
      } catch (error) {
        setAlert({
          isActive: true,
          type: 'error',
          message: 'Erro ao ativar / desativar coordenador.',
        });
      }
    },
    [coordinators],
  );

  const handleSubmit = useCallback(
    async (data: CreateCoordinatorData) => {
      try {
        formRefCreate.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .email('Digite um e-mail válido.')
            .required('E-mail obrigatório.'),
          password: Yup.string(),
          aviability: Yup.array().of(
            Yup.object().shape({
              date: Yup.date().required('Data obrigatória.'),
              booked: Yup.boolean(),
            }),
          ),
        });

        await schema.validate(data, { abortEarly: false });

        if (!data.password.length) delete data.password;

        const response = await api.post(`/coordinators`, data, {
          headers: {
            Authorization,
          },
        });

        const currentCoordinators = coordinators;
        currentCoordinators?.push(response.data);

        setCoordinators(currentCoordinators);

        setAlert({
          isActive: true,
          type: 'success',
          message: 'Coordenador cadastrado com sucesso.',
        });

        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRefCreate.current?.setErrors(errors);
        } else if (error.message.includes('400')) {
          setAlert({
            isActive: true,
            type: 'warning',
            message: 'E-mail já utilizado.',
          });
        } else {
          setAlert({
            isActive: true,
            type: 'error',
            message: 'Erro ao criar coordenador.',
          });
        }
      }
    },
    [coordinators],
  );

  const handleUpdateSubmit = useCallback(
    async (data: UpdateCoordinatorData) => {
      try {
        formRefUpdate.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string(),
          email: Yup.string().email('Digite um e-mail válido.'),
          password: Yup.string(),
        });

        await schema.validate(data, { abortEarly: false });

        if (!data.password) delete data.password;

        await api.put(`/coordinators/${coordinatorToUpdate?._id}`, data, {
          headers: {
            Authorization,
          },
        });

        const currentCoordinators = coordinators;
        const coordIdx = currentCoordinators.findIndex(
          (c) => c._id === coordinatorToUpdate?._id,
        );
        currentCoordinators[coordIdx] = {
          ...currentCoordinators[coordIdx],
          ...data,
        };

        setCoordinators([...currentCoordinators]);

        setAlert({
          isActive: true,
          type: 'success',
          message: 'Coordenador atualizado com sucesso.',
        });

        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRefCreate.current?.setErrors(errors);
        } else if (error.message.includes('400')) {
          setAlert({
            isActive: true,
            type: 'warning',
            message: 'E-mail já utilizado.',
          });
        } else {
          setAlert({
            isActive: true,
            type: 'error',
            message: 'Erro ao atualizar coordenador.',
          });
        }
      }
    },
    [coordinators, coordinatorToUpdate],
  );

  const handleAlert = useCallback(() => {
    setAlert({ isActive: false });
  }, []);

  return (
    <Container>
      <Button disabled>
        <IconButton>
          <SupervisorAccount />
        </IconButton>
        <h2>Coordenadores</h2>
      </Button>

      <div style={{ alignSelf: 'center' }}>
        <IconButton onClick={() => setOpen(true)}>
          <Add style={{ color: green[500] }} fontSize="large" />
        </IconButton>
      </div>

      <DialogForm
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        title="Criar Coordenador"
        formRef={formRefCreate}
      >
        <div className="row">
          <Input
            name="name"
            type="text"
            label="Nome"
            size={100}
            placeholder="Digite um nome"
          />
        </div>

        <div className="row">
          <Input
            name="email"
            type="email"
            label="E-mail"
            size={100}
            placeholder="Digite um e-mail"
          />
        </div>

        <div className="row">
          <Input
            name="password"
            type="password"
            label="Senha"
            size={100}
            placeholder="Digite uma senha"
          />
        </div>
      </DialogForm>

      <DialogForm
        open={!!coordinatorToUpdate}
        handleClose={handleClose}
        handleSubmit={handleUpdateSubmit}
        initialData={coordinatorToUpdate}
        title="Editar Coordenador"
        formRef={formRefUpdate}
      >
        <div className="row">
          <Input
            name="name"
            type="text"
            label="Nome"
            size={100}
            placeholder="Digite um nome"
          />
        </div>

        <div className="row">
          <Input
            name="email"
            type="email"
            label="E-mail"
            size={100}
            placeholder="Digite um e-mail"
          />
        </div>

        <div className="row">
          <Input
            name="password"
            type="password"
            label="Senha"
            size={100}
            placeholder="Digite uma senha"
          />
        </div>
      </DialogForm>

      <div className="cards-container">
        {coordinators?.map((coordinator) => (
          <div className="coordinator-card">
            <Card key={coordinator._id} className={classes.root}>
              <CardHeader
                avatar={(
                  <Avatar className={classes.avatar}>
                    {coordinator.name.split('')[0]}
                  </Avatar>
                )}
                action={
                  <Tooltip title="Deletar">
                    <IconButton>
                      <Delete
                        onClick={() => handleDelete(coordinator._id)}
                        color="error"
                      />
                    </IconButton>
                  </Tooltip>
                }
                title={coordinator.name}
                subheader={`Criado em ${moment(coordinator.createdAt).format(
                  'DD/MM/YYYY',
                )}`}
              />

              <CardContent>
                <Typography color="textPrimary" component="p" align="center">
                  {coordinator.email}
                </Typography>

                <Typography color="textSecondary" component="p" align="center">
                  {`Criado por ${coordinator.createdBy.name}`}
                </Typography>
              </CardContent>

              <CardActions
                disableSpacing
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div>
                  <Tooltip
                    title={`${coordinator.isActive ? 'Desativar' : 'Ativar'}`}
                  >
                    <Switch
                      checked={coordinator.isActive}
                      onClick={() => handleActivate(coordinator._id)}
                      name="isActive"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </Tooltip>
                </div>
                <Tooltip title="Editar">
                  <Button onClick={() => handleOpenUpdate(coordinator._id)}>
                    <Edit color="primary" />
                  </Button>
                </Tooltip>
              </CardActions>
            </Card>
          </div>
        ))}
      </div>

      {alert.isActive && (
        <Alert onClose={handleAlert} type={alert.type || 'success'}>
          {alert.message}
        </Alert>
      )}
    </Container>
  );
};

export default Coordinators;
