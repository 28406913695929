import React, { useState, useCallback, useRef, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import moment from 'moment';

import { Delete, Add, SupervisorAccount, Edit } from '@material-ui/icons';

import {
  Button,
  Typography,
  IconButton,
  CardActions,
  CardContent,
  CardHeader,
  Card,
  Tooltip,
} from '@material-ui/core';

import { green } from '@material-ui/core/colors';

import { Container, useStyles } from './styles';

import getValidationErrors from '../../utils/getValidationErrors';

import api from '../../services/api';
import Alert from '../../components/Alert';
import Input from '../../components/Input';
import DialogForm from '../../components/DialogForm';
import { useAuth } from '../../hooks/Auth';
import { AlertProps } from '../../utils/dtos';
import { CreateGroupData, Group, UpdateGroupData } from './dtos';

const Groups: React.FC = () => {
  const classes = useStyles();
  const { token } = useAuth();
  const formRefCreate = useRef<FormHandles>(null);
  const formRefUpdate = useRef<FormHandles>(null);

  const [open, setOpen] = React.useState(false);
  const [groups, setGroups] = useState<Group[]>([]);
  const [groupToUpdate, setGroupToUpdate] = useState<Group | null>(null);
  const [alert, setAlert] = useState<AlertProps>({
    isActive: false,
  });

  const Authorization = `Bearer ${token}`;

  useEffect(() => {
    async function getGroups() {
      try {
        const groupsResponse = await api.get('/groups', {
          headers: {
            Authorization,
          },
        });

        setGroups(groupsResponse.data);

        setAlert({
          isActive: true,
          type: 'success',
          message: 'Grupos listados com sucesso.',
        });
      } catch (error) {
        if (error.message.includes('401')) {
          setAlert({
            isActive: true,
            type: 'warning',
            message: 'Sessão expirada, faça login novamente.',
          });
        } else {
          setAlert({
            isActive: true,
            type: 'error',
            message: 'Erro ao listar grupos.',
          });
        }
      }
    }

    getGroups();
  }, []);

  const handleDelete = useCallback(
    async (groupId) => {
      try {
        await api.delete(`/groups/${groupId}`, {
          headers: {
            Authorization,
          },
        });

        setAlert({
          isActive: true,
          type: 'success',
          message: 'Grupo deletado com sucesso.',
        });

        if (groups?.length) {
          const newGroups = groups.filter((group) => group._id !== groupId);

          setGroups(newGroups);
        }
      } catch (error) {
        setAlert({
          isActive: true,
          type: 'error',
          message: 'Erro ao deletar grupo.',
        });
      }
    },
    [groups],
  );

  const handleOpenUpdate = useCallback(
    (groupId) => {
      const findIdx = groups.findIndex((g) => g._id === groupId);

      setGroupToUpdate(groups[findIdx]);
    },
    [groups],
  );

  const handleClose = useCallback(() => {
    setOpen(false);
    setGroupToUpdate(null);
  }, []);

  const handleSubmit = useCallback(
    async (data: CreateGroupData) => {
      try {
        formRefCreate.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          description: Yup.string(),
        });

        await schema.validate(data, { abortEarly: false });

        const { description } = data;
        if (!description || !description.length) delete data.description;

        const response = await api.post(`/groups`, data, {
          headers: {
            Authorization,
          },
        });

        const currentGroups = groups;
        currentGroups?.push(response.data);

        setGroups(currentGroups);

        setAlert({
          isActive: true,
          type: 'success',
          message: 'Grupo cadastrado com sucesso.',
        });

        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRefCreate.current?.setErrors(errors);
        } else {
          setAlert({
            isActive: true,
            type: 'error',
            message: 'Erro ao criar grupo.',
          });
        }
      }
    },
    [groups],
  );

  const handleUpdateSubmit = useCallback(
    async (data: UpdateGroupData) => {
      try {
        formRefUpdate.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string(),
          description: Yup.string(),
        });

        await schema.validate(data, { abortEarly: false });

        const { description } = data;
        if (!description || !description.length) delete data.description;

        await api.put(`/groups/${groupToUpdate?._id}`, data, {
          headers: {
            Authorization,
          },
        });

        const currentGroups = groups;
        const groupIdx = currentGroups.findIndex(
          (g) => g._id === groupToUpdate?._id,
        );
        currentGroups[groupIdx] = { ...currentGroups[groupIdx], ...data };

        setGroups([...currentGroups]);

        setAlert({
          isActive: true,
          type: 'success',
          message: 'Grupo atualizado com sucesso.',
        });

        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRefCreate.current?.setErrors(errors);
        } else {
          setAlert({
            isActive: true,
            type: 'error',
            message: 'Erro ao atualizar grupo.',
          });
        }
      }
    },
    [groups, groupToUpdate],
  );

  const handleAlert = useCallback(() => {
    setAlert({ isActive: false });
  }, []);

  return (
    <Container>
      <Button disabled>
        <IconButton>
          <SupervisorAccount />
        </IconButton>
        <h2>Grupos de Alunos</h2>
      </Button>

      <div style={{ alignSelf: 'center' }}>
        <IconButton onClick={() => setOpen(true)}>
          <Add style={{ color: green[500] }} fontSize="large" />
        </IconButton>
      </div>

      <DialogForm
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        title="Criar Grupo"
        formRef={formRefCreate}
      >
        <div className="row">
          <Input
            name="name"
            type="text"
            label="Nome"
            size={30}
            placeholder="Digite um nome"
          />

          <Input
            name="description"
            type="text"
            label="Descrição"
            size={70}
            placeholder="Digite uma descrição (opcional)"
          />
        </div>
      </DialogForm>

      <DialogForm
        open={!!groupToUpdate}
        handleClose={handleClose}
        handleSubmit={handleUpdateSubmit}
        title="Atualizar Grupo"
        formRef={formRefUpdate}
        initialData={groupToUpdate}
      >
        <div className="row">
          <Input
            name="name"
            type="text"
            label="Nome"
            size={30}
            placeholder="Digite um nome"
          />

          <Input
            name="description"
            type="text"
            label="Descrição"
            size={70}
            placeholder="Digite uma descrição (opcional)"
          />
        </div>
      </DialogForm>

      <div className="cards-container">
        {groups?.map((group) => (
          <div className="group-card">
            <Card key={group._id} className={classes.root}>
              <CardHeader
                action={
                  <Tooltip title="Deletar">
                    <IconButton>
                      <Delete
                        onClick={() => handleDelete(group._id)}
                        color="error"
                      />
                    </IconButton>
                  </Tooltip>
                }
                title={group.name}
                subheader={`Criado em ${moment(group.createdAt).format(
                  'DD/MM/YYYY',
                )}`}
              />

              <CardContent>
                <Typography color="textPrimary" component="p">
                  {group?.description || 'Sem descrição.'}
                </Typography>
              </CardContent>

              <CardActions
                disableSpacing
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Tooltip title="Editar">
                  <Button onClick={() => handleOpenUpdate(group._id)}>
                    <Edit color="primary" />
                  </Button>
                </Tooltip>
              </CardActions>
            </Card>
          </div>
        ))}
      </div>

      {alert.isActive && (
        <Alert onClose={handleAlert} type={alert.type || 'success'}>
          {alert.message}
        </Alert>
      )}
    </Container>
  );
};

export default Groups;
