import styled from 'styled-components';
import Select from 'react-select';

export const Container = styled.div`
  width: 100%;
`;

export const ReactSelect = styled(Select)`
  min-width: 90%;
`;
