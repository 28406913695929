import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';

import { PictureAsPdf, Archive } from '@material-ui/icons';

import {
  Button,
  IconButton,
  CardActions,
  CardHeader,
  Card,
  Tooltip,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';

import { Container, useStyles } from './styles';

import api from '../../services/api';

import Alert from '../../components/Alert';

import { useAuth } from '../../hooks/Auth';

import { AlertProps } from '../../utils/dtos';

import { AnnualRegistration } from './dtos';

const Rooms: React.FC = () => {
  const classes = useStyles();
  const { token } = useAuth();
  const [loading, setLoading] = useState(true);
  const [registrations, setRegistrations] = useState<AnnualRegistration[]>([]);

  const [alert, setAlert] = useState<AlertProps>({
    isActive: false,
  });

  const Authorization = `Bearer ${token}`;

  useEffect(() => {
    async function getRegistrations() {
      try {
        const registrationResponse = await api.get('/annualRegistrations', {
          headers: {
            Authorization,
          },
        });

        setRegistrations(registrationResponse.data);

        setAlert({
          isActive: true,
          type: 'success',
          message: 'Inscrições anuais listadas com sucesso.',
        });
      } catch (error) {
        if (error.message.includes('401')) {
          setAlert({
            isActive: true,
            type: 'warning',
            message: 'Sessão expirada, faça login novamente.',
          });
        } else {
          setAlert({
            isActive: true,
            type: 'error',
            message: 'Erro ao listar inscrições.',
          });
        }
      }
    }

    getRegistrations();
    setLoading(false);
  }, []);

  const handleDownload = useCallback(
    async (registrationId) => {
      setLoading(true);

      const currentRegistrations = registrations;
      const findIdx = currentRegistrations.findIndex(
        (r) => r._id === registrationId,
      );
      const registrationToDownload = currentRegistrations[findIdx];

      if (!registrationToDownload) return;

      try {
        const pdf = await api.get(
          `/annualRegistrations/${registrationId}/report`,
          {
            headers: {
              Authorization,
            },
          },
        );

        const blob = new Blob([new Uint8Array(Object.values(pdf.data)).buffer]);

        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, `${registrationToDownload.name}.pdf`);
        } else {
          const link = document.createElement('a');
          // Browsers that support HTML5 download attribute
          if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', `${registrationToDownload.name}.pdf`);
            link.setAttribute('type', 'application/pdf');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      } catch (error) {
        console.log('err ', error);
        if (error.message.includes('401')) {
          setAlert({
            isActive: true,
            type: 'warning',
            message: 'Sessão expirada, faça login novamente.',
          });
        } else {
          setAlert({
            isActive: true,
            type: 'error',
            message: 'Erro ao gerar arquivo.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [registrations],
  );

  const handleAlert = useCallback(() => {
    setAlert({ isActive: false });
  }, []);

  return (
    <Container>
      {/* <Backdrop open={loading}>
        <CircularProgress color="primary" />
      </Backdrop> */}

      <Button disabled>
        <IconButton>
          <Archive />
        </IconButton>
        <h2>Relatórios</h2>
      </Button>

      <div className="cards-container">
        {registrations?.map((registration) => (
          <div className="registration-card">
            <Card key={registration._id} className={classes.root}>
              <CardHeader
                title={registration.name}
                subheader={`Atualizado em ${moment(
                  registration.updatedAt,
                ).format('DD/MM/YYYY')} às ${moment(
                  registration.updatedAt,
                ).format('HH:mm')}`}
                action={
                  <Tooltip title="Baixar">
                    <IconButton>
                      <PictureAsPdf
                        onClick={() => handleDownload(registration._id)}
                        color="error"
                      />
                    </IconButton>
                  </Tooltip>
                }
              />

              <CardActions
                disableSpacing
                style={{ display: 'flex', justifyContent: 'space-between' }}
              />
            </Card>
          </div>
        ))}
      </div>

      {alert.isActive && (
        <Alert onClose={handleAlert} type={alert.type || 'success'}>
          {alert.message}
        </Alert>
      )}
    </Container>
  );
};

export default Rooms;
