import React, { useState, useCallback, useRef, useEffect } from 'react';
import { FormHandles, Scope } from '@unform/core';
import * as Yup from 'yup';
import moment from 'moment';

import { Delete, Add, Assignment, Edit } from '@material-ui/icons';

import {
  Button,
  Typography,
  IconButton,
  CardActions,
  CardContent,
  CardHeader,
  Card,
  Chip,
  Switch,
  Tooltip,
  Divider,
  FormLabel,
} from '@material-ui/core';

import { green } from '@material-ui/core/colors';

import { Container, useStyles, GroupFormContainer } from './styles';

import getValidationErrors from '../../utils/getValidationErrors';

import api from '../../services/api';

import Alert from '../../components/Alert';
import Input from '../../components/Input';
import DialogForm from '../../components/DialogForm';
import DatePicker from '../../components/DatePicker';

import { useAuth } from '../../hooks/Auth';

import { AlertProps } from '../../utils/dtos';
import { Group } from '../Groups/dtos';
import {
  CreateAnnualRegistrationData,
  AnnualRegistration,
  CreateSchedule,
  UpdateAnnualRegistrationData,
} from './dtos';

const Rooms: React.FC = () => {
  const classes = useStyles();
  const { token } = useAuth();
  const formRefCreate = useRef<FormHandles>(null);
  const formRefUpdate = useRef<FormHandles>(null);

  const [open, setOpen] = useState(false);
  const [registrations, setRegistrations] = useState<AnnualRegistration[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [
    registrationToUpdate,
    setRegistrationToUpdate,
  ] = useState<UpdateAnnualRegistrationData | null>(null);

  const [alert, setAlert] = useState<AlertProps>({
    isActive: false,
  });

  const Authorization = `Bearer ${token}`;

  useEffect(() => {
    async function getRegistrations() {
      try {
        const registrationResponse = await api.get('/annualRegistrations', {
          headers: {
            Authorization,
          },
        });

        setRegistrations(registrationResponse.data);

        const groupsResponse = await api.get('/groups', {
          headers: {
            Authorization,
          },
        });

        setGroups(groupsResponse.data);

        setAlert({
          isActive: true,
          type: 'success',
          message: 'Inscrições anuais listadas com sucesso.',
        });
      } catch (error) {
        if (error.message.includes('401')) {
          setAlert({
            isActive: true,
            type: 'warning',
            message: 'Sessão expirada, faça login novamente.',
          });
        } else {
          setAlert({
            isActive: true,
            type: 'error',
            message: 'Erro ao listar inscrições.',
          });
        }
      }
    }

    getRegistrations();
  }, []);

  const handleActivate = useCallback(
    async (registrationId) => {
      try {
        await api.get(
          `/annualRegistrations/${registrationId}/activateDeactivate`,
          {
            headers: {
              Authorization,
            },
          },
        );

        const currentRegistrations = registrations;
        const registrationIdx = currentRegistrations.findIndex(
          (r) => r._id === registrationId,
        );
        currentRegistrations[registrationIdx].isActive = !currentRegistrations[
          registrationIdx
        ].isActive;

        setRegistrations([...currentRegistrations]);
      } catch (error) {
        setAlert({
          isActive: true,
          type: 'error',
          message: 'Erro ao ativar / desativar inscrição.',
        });
      }
    },
    [registrations],
  );

  const handleDelete = useCallback(
    async (registrationId) => {
      try {
        await api.delete(`/annualRegistrations/${registrationId}`, {
          headers: {
            Authorization,
          },
        });

        setAlert({
          isActive: true,
          type: 'success',
          message: 'Inscrição anual deletada com sucesso.',
        });

        if (registrations?.length) {
          const newRegistrations = registrations.filter(
            (registration) => registration._id !== registrationId,
          );

          setRegistrations(newRegistrations);
        }
      } catch (error) {
        setAlert({
          isActive: true,
          type: 'error',
          message: 'Erro ao deletar inscrição anual.',
        });
      }
    },
    [registrations],
  );

  const handleOpenUpdate = useCallback(
    (registrationId) => {
      const currentRegistrations = registrations;
      const findIdx = currentRegistrations.findIndex(
        (r) => r._id === registrationId,
      );
      const registrationUpdate = currentRegistrations[findIdx];

      const updateRegistration: UpdateAnnualRegistrationData = {
        _id: registrationUpdate._id,
        name: registrationUpdate.name,
        year: registrationUpdate.year,
      };

      if (registrationUpdate.schedules) {
        updateRegistration.schedules = registrationUpdate.schedules.map(
          (schedule) => ({
            group: schedule.group.name,
            startTime: new Date(schedule.startTime),
            endTime: new Date(schedule.endTime),
          }),
        );
      }

      setRegistrationToUpdate(updateRegistration);
    },
    [registrations],
  );

  const handleClose = useCallback(() => {
    setOpen(false);
    setRegistrationToUpdate(null);
  }, []);

  const handleSubmit = useCallback(
    async (data: CreateAnnualRegistrationData) => {
      try {
        formRefCreate.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          year: Yup.number()
            .typeError('Digite um número válido')
            .min(2020, 'Anos anteriores não permitidos')
            .max(2100, 'Digite um ano anterior')
            .required('Ano obrigatório'),
          schedules: Yup.array().of(
            Yup.object().shape({
              startTime: Yup.date().typeError('Horário de início obrigatório'),
              endTime: Yup.date()
                .typeError('Horário de término obrigatório')
                .min(
                  Yup.ref('startTime'),
                  'Data de término deve ser após o início',
                ),
              group: Yup.string().required('Grupo obrigatório'),
            }),
          ),
        });

        await schema.validate(data, { abortEarly: false });

        data.schedules = data.schedules.map((schedule: CreateSchedule) => {
          const foundGroup: Group | undefined = groups.find(
            (g) => g.name === schedule.group,
          );

          return {
            startTime: schedule.startTime,
            endTime: schedule.endTime,
            group: foundGroup ? foundGroup._id : 'asd',
          };
        });

        const response = await api.post(`/annualRegistrations`, data, {
          headers: {
            Authorization,
          },
        });

        const currentRegistrations = registrations;

        response.data.schedules = response.data.schedules.map(
          (schedule: CreateSchedule) => {
            const foundGroup: Group | undefined = groups.find(
              (g) => g._id === schedule.group,
            );

            return {
              startTime: schedule.startTime,
              endTime: schedule.endTime,
              group: {
                _id: schedule.group,
                name: foundGroup ? foundGroup.name : 'Grupo não encontrado',
              },
            };
          },
        );

        currentRegistrations?.push(response.data);

        setRegistrations(currentRegistrations);

        setAlert({
          isActive: true,
          type: 'success',
          message: 'Incrição Anual cadastrada com sucesso.',
        });

        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRefCreate.current?.setErrors(errors);
        } else {
          setAlert({
            isActive: true,
            type: 'error',
            message: 'Erro ao criar inscrição anual.',
          });
        }
      }
    },
    [registrations, groups],
  );

  const handleUpdateSubmit = useCallback(
    async (data: UpdateAnnualRegistrationData) => {
      try {
        formRefCreate.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          year: Yup.number()
            .typeError('Digite um número válido')
            .min(2020, 'Anos anteriores não permitidos')
            .max(2100, 'Digite um ano anterior')
            .required('Ano obrigatório'),
          schedules: Yup.array().of(
            Yup.object().shape({
              startTime: Yup.date().typeError('Horário de início obrigatório'),
              endTime: Yup.date()
                .typeError('Horário de término obrigatório')
                .min(
                  Yup.ref('startTime'),
                  'Data de término deve ser após o início',
                ),
              group: Yup.string().required('Grupo obrigatório'),
            }),
          ),
        });

        await schema.validate(data, { abortEarly: false });

        if (data.schedules) {
          data.schedules = data.schedules.map((schedule: CreateSchedule) => {
            const foundGroup: Group | undefined = groups.find(
              (g) => g.name === schedule.group,
            );

            return {
              startTime: schedule.startTime,
              endTime: schedule.endTime,
              group: foundGroup ? foundGroup._id : 'asd',
            };
          });
        }

        await api.put(
          `/annualRegistrations/${registrationToUpdate?._id}`,
          data,
          {
            headers: {
              Authorization,
            },
          },
        );

        const currentRegistrations = registrations;
        const findIdx = currentRegistrations.findIndex(
          (r) => r._id === registrationToUpdate?._id,
        );

        currentRegistrations[findIdx] = {
          ...currentRegistrations[findIdx],
          year: data.year || currentRegistrations[findIdx].year,
          name: data.name || currentRegistrations[findIdx].name,
        };

        if (data.schedules) {
          currentRegistrations[findIdx].schedules = data.schedules.map(
            (schedule: CreateSchedule) => {
              const foundGroup: Group | undefined = groups.find(
                (g) => g._id === schedule.group,
              );

              return {
                _id: 'asd',
                startTime: schedule.startTime,
                endTime: schedule.endTime,
                group: {
                  _id: schedule.group,
                  name: foundGroup ? foundGroup.name : 'Grupo não encontrado',
                },
              };
            },
          );
        }

        setRegistrations([...currentRegistrations]);

        setAlert({
          isActive: true,
          type: 'success',
          message: 'Incrição Anual atualizada com sucesso.',
        });

        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRefCreate.current?.setErrors(errors);
        } else {
          setAlert({
            isActive: true,
            type: 'error',
            message: 'Erro ao atualizar inscrição anual.',
          });
        }
      }
    },
    [registrations, groups, registrationToUpdate],
  );

  const handleAlert = useCallback(() => {
    setAlert({ isActive: false });
  }, []);

  return (
    <Container>
      <Button disabled>
        <IconButton>
          <Assignment />
        </IconButton>
        <h2>Inscrição Anual</h2>
      </Button>

      <div style={{ alignSelf: 'center' }}>
        <IconButton onClick={() => setOpen(true)}>
          <Add style={{ color: green[500] }} fontSize="large" />
        </IconButton>
      </div>

      <DialogForm
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        title="Criar Inscrição Anual"
        formRef={formRefCreate}
      >
        <div className="row">
          <Input
            name="name"
            type="text"
            label="Nome"
            placeholder="Digite um nome"
          />

          <Input
            name="year"
            type="number"
            label="Ano"
            placeholder="Digite o ano para inscrições"
          />
        </div>

        <GroupFormContainer>
          <div className="row">
            <FormLabel className="label">Horários de inscrições</FormLabel>
          </div>

          {groups?.map((group, index) => (
            <div className="row">
              <Scope path={`schedules[${index}]`}>
                <Input
                  name="group"
                  type="text"
                  label="Grupo"
                  defaultValue={group.name}
                  readOnly
                />

                <DatePicker name="startTime" label="Início" />

                <DatePicker name="endTime" label="Fim" />
              </Scope>
            </div>
          ))}
        </GroupFormContainer>

        <Divider />
      </DialogForm>

      <DialogForm
        open={!!registrationToUpdate}
        handleClose={handleClose}
        handleSubmit={handleUpdateSubmit}
        title="Atualizar Inscrição Anual"
        formRef={formRefUpdate}
        initialData={registrationToUpdate}
      >
        <div className="row">
          <Input
            name="name"
            type="text"
            label="Nome"
            placeholder="Digite um nome"
          />

          <Input
            name="year"
            type="number"
            label="Ano"
            placeholder="Digite o ano para inscrições"
          />
        </div>

        <GroupFormContainer>
          <div className="row">
            <FormLabel className="label">Horários de inscrições</FormLabel>
          </div>

          {groups?.map((group, index) => (
            <div className="row">
              <Scope path={`schedules[${index}]`}>
                <Input
                  name="group"
                  type="text"
                  label="Grupo"
                  defaultValue={group.name}
                  readOnly
                />

                <DatePicker name="startTime" label="Início" />

                <DatePicker name="endTime" label="Fim" />
              </Scope>
            </div>
          ))}
        </GroupFormContainer>

        <Divider />
      </DialogForm>

      <div className="cards-container">
        {registrations?.map((registration) => (
          <div className="registration-card">
            <Card key={registration._id} className={classes.root}>
              <CardHeader
                action={
                  <Tooltip title="Deletar">
                    <IconButton>
                      <Delete
                        onClick={() => handleDelete(registration._id)}
                        color="error"
                      />
                    </IconButton>
                  </Tooltip>
                }
                title={registration.name}
                subheader={`Criado em ${moment(registration.createdAt).format(
                  'DD/MM/YYYY',
                )}`}
              />

              <CardContent>
                <Typography color="textPrimary" align="center">
                  <div className="student-groups">
                    {registration.schedules.map((schedule) => (
                      <Tooltip
                        title={`${moment(schedule.startTime).format(
                          'DD/MM/YYYY HH:mm',
                        )} até ${moment(schedule.endTime).format(
                          'DD/MM/YYYY HH:mm',
                        )}`}
                      >
                        <Chip
                          label={schedule.group.name}
                          variant="outlined"
                          color="primary"
                          style={{ margin: '2px', fontWeight: 'bolder' }}
                        />
                      </Tooltip>
                    ))}
                  </div>
                </Typography>
              </CardContent>

              <CardActions
                disableSpacing
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Typography color="textPrimary" align="center">
                  <Chip
                    label={`Ano: ${registration.year}`}
                    variant="outlined"
                    color="default"
                    style={{ fontWeight: 'bolder' }}
                  />
                </Typography>

                <div style={{ display: 'flex' }}>
                  <Tooltip
                    title={`${registration.isActive ? 'Desativar' : 'Ativar'}`}
                  >
                    <Switch
                      checked={registration.isActive}
                      onClick={() => handleActivate(registration._id)}
                      name="isActive"
                    />
                  </Tooltip>
                  <Tooltip title="Editar">
                    <Button onClick={() => handleOpenUpdate(registration._id)}>
                      <Edit color="primary" />
                    </Button>
                  </Tooltip>
                </div>
              </CardActions>
            </Card>
          </div>
        ))}
      </div>

      {alert.isActive && (
        <Alert onClose={handleAlert} type={alert.type || 'success'}>
          {alert.message}
        </Alert>
      )}
    </Container>
  );
};

export default Rooms;
