import React, { useRef, useState, useEffect, useCallback } from 'react';
import { ReactDatePickerProps, registerLocale } from 'react-datepicker';
import { useField } from '@unform/core';
import 'react-datepicker/dist/react-datepicker.css';
import ptBr from 'date-fns/locale/pt-BR';
import { IconButton, FormLabel } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';

import { Container, CustomDatePicker, DatePickerContainer } from './styles';

registerLocale('pt-br', ptBr);

interface Props extends Omit<ReactDatePickerProps, 'onChange'> {
  name: string;
  label: string;
}
const DatePicker: React.FC<Props> = ({ name, label, ...rest }) => {
  const datepickerRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [date, setDate] = useState(defaultValue || null);
  const [open, setOpen] = useState(false);

  const handleOpenClose = useCallback(() => {
    if (open) setOpen(false);
    else setOpen(true);
  }, [open]);

  const handleChangeDate = useCallback(
    (selectedDate: Date | [Date, Date] | null) => {
      setDate(selectedDate);
      setOpen(false);
    },
    [],
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: 'props.selected',
      clearValue: (ref: any) => {
        ref.clear();
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <FormLabel>{label}</FormLabel>
      {error && (
        <FormLabel error style={{ fontSize: '0.75rem', marginLeft: '2px' }}>
          {error}
        </FormLabel>
      )}

      <DatePickerContainer>
        <IconButton onClick={() => handleOpenClose()}>
          <CalendarToday color="secondary" />
        </IconButton>

        <CustomDatePicker
          ref={datepickerRef}
          selected={date}
          onChange={(selectedDate) => handleChangeDate(selectedDate)}
          locale="pt-br"
          timeFormat="p"
          timeIntervals={30}
          dateFormat="Pp"
          showTimeSelect
          placeholderText="__/__/__"
          onClickOutside={() => setOpen(false)}
          popperProps={{ positionFixed: true }}
          open={open}
          {...rest}
        />
      </DatePickerContainer>
    </Container>
  );
};
export default DatePicker;
