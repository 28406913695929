import React, { useState, useCallback, useRef, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import moment from 'moment';

import { Delete, Add, MeetingRoom, Edit, DesktopMac } from '@material-ui/icons';

import {
  Button,
  Typography,
  IconButton,
  CardActions,
  CardContent,
  CardHeader,
  Card,
  Checkbox,
  FormControlLabel,
  Switch,
  Tooltip,
} from '@material-ui/core';

import { green } from '@material-ui/core/colors';

import { Container, useStyles } from './styles';

import getValidationErrors from '../../utils/getValidationErrors';

import api from '../../services/api';
import Alert from '../../components/Alert';
import Input from '../../components/Input';
import DialogForm from '../../components/DialogForm';
import { useAuth } from '../../hooks/Auth';
import { AlertProps } from '../../utils/dtos';
import { CreateRoomData, Room, UpdateRoomData } from './dtos';

const Rooms: React.FC = () => {
  const classes = useStyles();
  const { token } = useAuth();
  const formRefCreate = useRef<FormHandles>(null);
  const formRefUpdate = useRef<FormHandles>(null);

  const [open, setOpen] = useState(false);
  const [hasProjector, setHasProjector] = useState(false);
  const [rooms, setRooms] = useState<Room[]>([]);
  const [roomToUpdate, setRoomToUpdate] = useState<Room | null>(null);
  const [alert, setAlert] = useState<AlertProps>({
    isActive: false,
  });

  const Authorization = `Bearer ${token}`;

  useEffect(() => {
    async function getRooms() {
      try {
        const roomsResponse = await api.get('/rooms', {
          headers: {
            Authorization,
          },
        });

        setRooms(roomsResponse.data);

        setAlert({
          isActive: true,
          type: 'success',
          message: 'Salas listadas com sucesso.',
        });
      } catch (error) {
        if (error.message.includes('401')) {
          setAlert({
            isActive: true,
            type: 'warning',
            message: 'Sessão expirada, faça login novamente.',
          });
        } else {
          setAlert({
            isActive: true,
            type: 'error',
            message: 'Erro ao listar salas.',
          });
        }
      }
    }

    getRooms();
  }, []);

  const handleDelete = useCallback(
    async (roomId) => {
      try {
        await api.delete(`/rooms/${roomId}`, {
          headers: {
            Authorization,
          },
        });

        setAlert({
          isActive: true,
          type: 'success',
          message: 'Sala deletada com sucesso.',
        });

        if (rooms?.length) {
          const newRooms = rooms.filter((room) => room._id !== roomId);

          setRooms(newRooms);
        }
      } catch (error) {
        setAlert({
          isActive: true,
          type: 'error',
          message: 'Erro ao deletar sala.',
        });
      }
    },
    [rooms],
  );

  const handleActivate = useCallback(
    async (roomId) => {
      try {
        await api.get(`/rooms/${roomId}/activateDeactivate`, {
          headers: {
            Authorization,
          },
        });

        const currentRooms = rooms;
        const roomIdx = currentRooms.findIndex((r) => r._id === roomId);
        currentRooms[roomIdx].isActive = !currentRooms[roomIdx].isActive;

        setRooms([...currentRooms]);
      } catch (error) {
        setAlert({
          isActive: true,
          type: 'error',
          message: 'Erro ao ativar / desativar sala.',
        });
      }
    },
    [rooms],
  );

  const handleOpenUpdate = useCallback(
    (roomId) => {
      const roomIdx = rooms.findIndex((r) => r._id === roomId);
      setHasProjector(rooms[roomIdx].hasProjector);
      setRoomToUpdate(rooms[roomIdx]);
    },
    [rooms],
  );

  const handleClose = useCallback(() => {
    setOpen(false);
    setHasProjector(false);
    setRoomToUpdate(null);
  }, []);

  const handleHasProjector = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setHasProjector(event.target.checked);
    },
    [],
  );

  const handleSubmit = useCallback(
    async (data: CreateRoomData) => {
      try {
        formRefCreate.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          description: Yup.string(),
        });

        await schema.validate(data, { abortEarly: false });

        const { description } = data;
        if (!description || !description.length) delete data.description;

        data.hasProjector = hasProjector;

        const response = await api.post(`/rooms`, data, {
          headers: {
            Authorization,
          },
        });

        const currentRooms = rooms;
        currentRooms?.push(response.data);

        setRooms(currentRooms);

        setAlert({
          isActive: true,
          type: 'success',
          message: 'Sala cadastrada com sucesso.',
        });

        handleClose();
        setHasProjector(false);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRefCreate.current?.setErrors(errors);
        } else {
          setAlert({
            isActive: true,
            type: 'error',
            message: 'Erro ao criar sala.',
          });
        }
      }
    },
    [rooms, hasProjector],
  );

  const handleUpdateSubmit = useCallback(
    async (data: UpdateRoomData) => {
      try {
        formRefUpdate.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          description: Yup.string(),
        });

        await schema.validate(data, { abortEarly: false });

        const { description } = data;
        if (!description || !description.length) delete data.description;

        data.hasProjector = hasProjector;

        const response = await api.put(`/rooms/${roomToUpdate?._id}`, data, {
          headers: {
            Authorization,
          },
        });

        const currentRooms = rooms;
        const roomIdx = currentRooms.findIndex(
          (r) => r._id === roomToUpdate?._id,
        );
        currentRooms[roomIdx] = { ...currentRooms[roomIdx], ...data };

        setRooms([...currentRooms]);

        setAlert({
          isActive: true,
          type: 'success',
          message: 'Sala atualizada com sucesso.',
        });

        handleClose();
        setHasProjector(false);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRefCreate.current?.setErrors(errors);
        } else {
          setAlert({
            isActive: true,
            type: 'error',
            message: 'Erro ao atualizar sala.',
          });
        }
      }
    },
    [rooms, hasProjector, roomToUpdate],
  );

  const handleAlert = useCallback(() => {
    setAlert({ isActive: false });
  }, []);

  return (
    <Container>
      <Button disabled>
        <IconButton>
          <MeetingRoom />
        </IconButton>
        <h2>Salas</h2>
      </Button>

      <div style={{ alignSelf: 'center' }}>
        <IconButton onClick={() => setOpen(true)}>
          <Add style={{ color: green[500] }} fontSize="large" />
        </IconButton>
      </div>

      <DialogForm
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        title="Criar Sala"
        formRef={formRefCreate}
      >
        <div className="row">
          <Input
            name="name"
            type="text"
            label="Nome"
            size={40}
            placeholder="Digite um nome"
          />

          <Input
            name="description"
            type="text"
            label="Descrição"
            size={60}
            placeholder="Digite uma descrição (opcional)"
          />
        </div>

        <div className="row">
          <FormControlLabel
            control={(
              <Checkbox
                checked={hasProjector}
                color="primary"
                onChange={handleHasProjector}
              />
            )}
            label="Tem projetor"
          />
        </div>
      </DialogForm>

      <DialogForm
        open={!!roomToUpdate}
        handleClose={handleClose}
        handleSubmit={handleUpdateSubmit}
        title="Atualizar Sala"
        formRef={formRefUpdate}
        initialData={roomToUpdate}
      >
        <div className="row">
          <Input
            name="name"
            type="text"
            label="Nome"
            size={40}
            placeholder="Digite um nome"
          />

          <Input
            name="description"
            type="text"
            label="Descrição"
            size={60}
            placeholder="Digite uma descrição (opcional)"
          />
        </div>

        <div className="row">
          <FormControlLabel
            control={(
              <Checkbox
                checked={hasProjector}
                color="primary"
                onChange={handleHasProjector}
              />
            )}
            label="Tem projetor"
          />
        </div>
      </DialogForm>

      <div className="cards-container">
        {rooms?.map((room) => (
          <div className="room-card">
            <Card key={room._id} className={classes.root}>
              <CardHeader
                action={
                  <Tooltip title="Deletar">
                    <IconButton>
                      <Delete
                        onClick={() => handleDelete(room._id)}
                        color="error"
                      />
                    </IconButton>
                  </Tooltip>
                }
                title={room.name}
                subheader={`Criado em ${moment(room.createdAt).format(
                  'DD/MM/YYYY',
                )}`}
              />

              <CardContent>
                <Typography color="textPrimary" component="p">
                  {room?.description || 'Sem descrição.'}
                </Typography>
              </CardContent>

              <CardActions
                disableSpacing
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div>
                  <Tooltip title={room.isActive ? 'Desativar' : 'Ativar'}>
                    <Switch
                      checked={room.isActive}
                      onClick={() => handleActivate(room._id)}
                      name="isActive"
                    />
                  </Tooltip>
                </div>

                <div>
                  <Tooltip
                    title={room.hasProjector ? 'Com projetor' : 'Sem projetor'}
                  >
                    <Button>
                      <DesktopMac
                        color={room.hasProjector ? 'primary' : 'disabled'}
                      />
                    </Button>
                  </Tooltip>

                  <Tooltip title="Editar">
                    <Button onClick={() => handleOpenUpdate(room._id)}>
                      <Edit color="primary" />
                    </Button>
                  </Tooltip>
                </div>
              </CardActions>
            </Card>
          </div>
        ))}
      </div>

      {alert.isActive && (
        <Alert onClose={handleAlert} type={alert.type || 'success'}>
          {alert.message}
        </Alert>
      )}
    </Container>
  );
};

export default Rooms;
