import styled from 'styled-components';
import DatePicker from 'react-datepicker';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const DatePickerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const CustomDatePicker = styled(DatePicker)`
  border: 0;
`;
