import React, { useState, useEffect, useCallback, useRef } from 'react';
import moment from 'moment';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { green } from '@material-ui/core/colors';
import { Delete, Security, VerifiedUser, Add } from '@material-ui/icons';

import {
  Button,
  Typography,
  IconButton,
  Avatar,
  CardActions,
  CardContent,
  CardHeader,
  Card,
  Tooltip,
} from '@material-ui/core';

import { Container, useStyles } from './styles';

import getValidationErrors from '../../utils/getValidationErrors';

import api from '../../services/api';
import Alert from '../../components/Alert';
import Input from '../../components/Input';
import DialogForm from '../../components/DialogForm';
import { useAuth } from '../../hooks/Auth';

import { Admin, CreateAdminData, UpdateAdminPasswordData } from './dtos';

import { AlertProps } from '../../utils/dtos';

const Admins: React.FC = () => {
  const classes = useStyles();
  const { token, user } = useAuth();
  const formRefCreate = useRef<FormHandles>(null);
  const formRefUpdate = useRef<FormHandles>(null);

  const [open, setOpen] = React.useState(false);
  const [admins, setAdmins] = useState<Admin[]>([]);
  const [adminToUpdate, setAdminToUpdate] = useState<string>();
  const [alert, setAlert] = useState<AlertProps>({
    isActive: false,
  });

  const Authorization = `Bearer ${token}`;

  useEffect(() => {
    async function getAdmins() {
      try {
        const adminsResponse = await api.get('/admins', {
          headers: {
            Authorization,
          },
        });

        setAdmins(adminsResponse.data);
        setAlert({
          isActive: true,
          type: 'success',
          message: 'Administradores listados com sucesso.',
        });
      } catch (error) {
        if (error.message.includes('401')) {
          setAlert({
            isActive: true,
            type: 'warning',
            message: 'Sessão expirada, faça o login novamente.',
          });
        } else {
          setAlert({
            isActive: true,
            type: 'error',
            message: 'Erro ao listar administradores.',
          });
        }
      }
    }

    getAdmins();
  }, []);

  const handleDelete = useCallback(
    async (adminId) => {
      try {
        await api.delete(`/admins/${adminId}`, {
          headers: {
            Authorization,
          },
        });

        setAlert({
          isActive: true,
          type: 'success',
          message: 'Administrador deletado com sucesso.',
        });

        if (admins?.length) {
          const newAdmins = admins.filter((admin) => admin._id !== adminId);

          setAdmins(newAdmins);
        }
      } catch (error) {
        setAlert({
          isActive: true,
          type: 'error',
          message: 'Erro ao deletar administrador.',
        });
      }
    },
    [admins],
  );

  const handleAlert = useCallback(() => {
    setAlert({ isActive: false });
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleOpenUpdate = useCallback((adminId) => {
    setAdminToUpdate(adminId);
  }, []);

  const handleCloseUpdate = useCallback(() => {
    setAdminToUpdate('');
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = useCallback(
    async (data: CreateAdminData) => {
      try {
        formRefCreate.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .email('Digite um e-mail válido.')
            .required('E-mail obrigatório.'),
          password: Yup.string().required('Senha obrigatória.'),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await api.post(`/admins`, data, {
          headers: {
            Authorization,
          },
        });

        const currentAdmins = admins;
        currentAdmins?.push(response.data);

        setAdmins(currentAdmins);

        setAlert({
          isActive: true,
          type: 'success',
          message: 'Administrador cadastrado com sucesso.',
        });

        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRefCreate.current?.setErrors(errors);
        } else {
          setAlert({
            isActive: true,
            type: 'error',
            message: 'Erro ao criar administrador.',
          });
        }
      }
    },
    [admins],
  );

  const handlePasswordUpdateSubmit = useCallback(
    async (data: UpdateAdminPasswordData) => {
      try {
        formRefUpdate.current?.setErrors({});

        const schema = Yup.object().shape({
          password: Yup.string().required('Senha obrigatória.'),
          passwordConfirm: Yup.string()
            .oneOf([Yup.ref('password')], 'Senhas devem ser iguais.')
            .required('Confirmação obrigatória.'),
        });

        await schema.validate(data, { abortEarly: false });

        await api.put(`/admins/${adminToUpdate}/password`, data, {
          headers: {
            Authorization,
          },
        });

        setAlert({
          isActive: true,
          type: 'success',
          message: 'Senha do Admin atualizada com sucesso.',
        });

        handleCloseUpdate();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRefUpdate.current?.setErrors(errors);
        } else {
          setAlert({
            isActive: true,
            type: 'error',
            message: 'Erro ao atualizar senha do Admin.',
          });
        }
      }
    },
    [adminToUpdate],
  );

  return (
    <Container>
      <Button disabled>
        <IconButton>
          <VerifiedUser />
        </IconButton>
        <h2>Administradores</h2>
      </Button>

      <div style={{ alignSelf: 'center' }}>
        <IconButton onClick={handleClickOpen}>
          <Add style={{ color: green[500] }} fontSize="large" />
        </IconButton>
      </div>

      <DialogForm
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        title="Criar Administrador"
        formRef={formRefCreate}
      >
        <div className="row">
          <Input
            name="name"
            type="text"
            label="Nome"
            size={100}
            placeholder="Digite um nome"
          />
        </div>

        <div className="row">
          <Input
            name="email"
            type="email"
            label="E-mail"
            size={100}
            placeholder="Digite um e-mail"
          />
        </div>

        <div className="row">
          <Input
            name="password"
            type="password"
            label="Senha"
            size={100}
            placeholder="Digite uma senha"
          />
        </div>
      </DialogForm>

      <DialogForm
        open={!!adminToUpdate?.length}
        handleClose={handleCloseUpdate}
        handleSubmit={handlePasswordUpdateSubmit}
        title="Atualizar Senha"
        formRef={formRefUpdate}
      >
        <div className="row">
          <Input
            name="password"
            type="password"
            label="Nova Senha"
            size={50}
            placeholder="Digite a nova senha"
          />

          <Input
            name="passwordConfirm"
            type="password"
            label="Confirme a Senha"
            size={50}
            placeholder="Confirme a nova senha"
          />
        </div>
      </DialogForm>

      <div className="cards-container">
        {admins?.map((admin) => (
          <div className="admin-card">
            <Card key={admin._id} className={classes.root}>
              <CardHeader
                avatar={(
                  <Avatar className={classes.avatar}>
                    {admin.name.split('')[0]}
                  </Avatar>
                )}
                action={
                  user.isOwner && (
                    <Tooltip title="Deletar">
                      <IconButton>
                        <Delete
                          onClick={() => handleDelete(admin._id)}
                          color="error"
                        />
                      </IconButton>
                    </Tooltip>
                  )
                }
                title={admin.name}
                subheader={`Criado em ${moment(admin.createdAt).format(
                  'DD/MM/YYYY',
                )}`}
              />

              <CardContent>
                <Typography color="textPrimary" component="p" align="center">
                  {admin.email}
                </Typography>
              </CardContent>

              <CardActions disableSpacing>
                {user.isOwner && (
                  <Button onClick={() => handleOpenUpdate(admin._id)}>
                    <IconButton aria-label="edit">
                      <Security color="primary" />
                    </IconButton>
                    Atualizar senha
                  </Button>
                )}
              </CardActions>
            </Card>
          </div>
        ))}
      </div>

      {alert.isActive && (
        <Alert onClose={handleAlert} type={alert.type || 'success'}>
          {alert.message}
        </Alert>
      )}
    </Container>
  );
};

export default Admins;
