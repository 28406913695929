import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';

import { OptionTypeBase, Props as SelectProps } from 'react-select';

import { FormLabel } from '@material-ui/core';

import { Container, ReactSelect } from './styles';

interface Props extends SelectProps<OptionTypeBase> {
  name: string;
}

const Select: React.FC<Props> = ({ name, ...rest }) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <Container>
      <ReactSelect
        defaultValue={defaultValue}
        theme={(theme: any) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#3f50b5',
            primary25: '#f4f4f4',
          },
        })}
        ref={selectRef}
        classNamePrefix="react-select"
        {...rest}
      />

      {error && (
        <FormLabel error style={{ fontSize: '0.75rem', marginLeft: '3px' }}>
          {error}
        </FormLabel>
      )}
    </Container>
  );
};

export default Select;
