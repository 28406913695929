import React, { useState, useCallback, useRef, useEffect } from 'react';
import 'date-fns';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import moment from 'moment';

import { Delete, Add, PersonAdd, Edit } from '@material-ui/icons';

import {
  Button,
  Typography,
  IconButton,
  Avatar,
  CardActions,
  CardContent,
  CardHeader,
  Card,
  Chip,
  Tooltip,
} from '@material-ui/core';

import { green } from '@material-ui/core/colors';

import { Container, useStyles } from './styles';

import getValidationErrors from '../../utils/getValidationErrors';

import api from '../../services/api';
import Alert from '../../components/Alert';
import Input from '../../components/Input';
import DialogForm from '../../components/DialogForm';
import Checkbox from '../../components/Checkbox';
import DatePicker from '../../components/DatePicker';

import { useAuth } from '../../hooks/Auth';
import { AlertProps } from '../../utils/dtos';
import { CreateStudentData, Student, UpdateStudentData } from './dtos';
import { Group } from '../Groups/dtos';

const Students: React.FC = () => {
  const classes = useStyles();
  const { token } = useAuth();
  const formRefCreate = useRef<FormHandles>(null);
  const formRefUpdate = useRef<FormHandles>(null);

  const [open, setOpen] = useState(false);
  const [students, setStudents] = useState<Student[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);

  const [studentToUpdate, setStudentToUpdate] = useState<Student | null>();
  const [alert, setAlert] = useState<AlertProps>({
    isActive: false,
  });

  const Authorization = `Bearer ${token}`;

  useEffect(() => {
    async function getStudents() {
      try {
        const studentsResponse = await api.get('/students', {
          headers: {
            Authorization,
          },
        });

        setStudents(studentsResponse.data);

        const groupsResponse = await api.get('/groups', {
          headers: {
            Authorization,
          },
        });

        setGroups(groupsResponse.data);

        setAlert({
          isActive: true,
          type: 'success',
          message: 'Alunos listados com sucesso.',
        });
      } catch (error) {
        if (error.message.includes('401')) {
          setAlert({
            isActive: true,
            type: 'warning',
            message: 'Sessão expirada, faça login novamente.',
          });
        } else {
          setAlert({
            isActive: true,
            type: 'error',
            message: 'Erro ao listar alunos.',
          });
        }
      }
    }

    getStudents();
  }, []);

  const handleDelete = useCallback(
    async (studentId) => {
      try {
        await api.delete(`/students/${studentId}`, {
          headers: {
            Authorization,
          },
        });

        setAlert({
          isActive: true,
          type: 'success',
          message: 'Aluno deletado com sucesso.',
        });

        if (students?.length) {
          const newStudents = students.filter(
            (student) => student._id !== studentId,
          );

          setStudents(newStudents);
        }
      } catch (error) {
        setAlert({
          isActive: true,
          type: 'error',
          message: 'Erro ao deletar aluno.',
        });
      }
    },
    [students],
  );

  const handleOpenUpdate = useCallback(
    (studentId) => {
      const findIdx = students.findIndex((s) => s._id === studentId);
      const newStudent = students[findIdx];
      newStudent.dateOfBirth = newStudent.dateOfBirth
        ? new Date(String(newStudent.dateOfBirth))
        : undefined;

      setStudentToUpdate(newStudent);
    },
    [students],
  );

  const handleClose = useCallback(() => {
    setOpen(false);
    setStudentToUpdate(null);
  }, []);

  const handleSubmit = useCallback(
    async (data: CreateStudentData) => {
      try {
        formRefCreate.current?.setErrors({});

        const schema = Yup.object().shape({
          firstName: Yup.string().required('Nome obrigatório'),
          lastName: Yup.string().required('Sobrenome obrigatório'),
          email: Yup.string()
            .email('Digite um e-mail válido.')
            .required('E-mail obrigatório.'),
          password: Yup.string().required('Senha obrigatória'),
          dateOfBirth: Yup.date().nullable(),
          address: Yup.string(),
          groups: Yup.array()
            .of(Yup.string())
            .min(1, 'Insira ao menos um grupo'),
        });

        await schema.validate(data, { abortEarly: false });

        const { address, dateOfBirth } = data;

        if (!dateOfBirth) delete data.dateOfBirth;
        if (!address) delete data.address;

        const response = await api.post(`/students`, data, {
          headers: {
            Authorization,
          },
        });

        const currentStudents = students;

        response.data.groups = response.data.groups.map((groupId: string) => {
          const foundGroup: Group | undefined = groups.find(
            (g) => g._id === groupId,
          );

          return {
            _id: groupId,
            name: foundGroup ? foundGroup.name : '',
          };
        });

        currentStudents?.push(response.data);

        setStudents(currentStudents);

        setAlert({
          isActive: true,
          type: 'success',
          message: 'Aluno cadastrado com sucesso.',
        });

        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRefCreate.current?.setErrors(errors);
        } else if (error.message.includes('400')) {
          setAlert({
            isActive: true,
            type: 'warning',
            message: 'E-mail já utilizado.',
          });
        } else {
          setAlert({
            isActive: true,
            type: 'error',
            message: 'Erro ao criar aluno.',
          });
        }
      }
    },
    [students, groups],
  );

  const handleUpdateSubmit = useCallback(
    async (data: UpdateStudentData) => {
      try {
        formRefUpdate.current?.setErrors({});

        const schema = Yup.object().shape({
          firstName: Yup.string(),
          lastName: Yup.string(),
          email: Yup.string().email('Digite um e-mail válido.'),
          password: Yup.string(),
          passwordConfirm: Yup.string().oneOf(
            [Yup.ref('password')],
            'Senhas devem ser iguais.',
          ),
          dateOfBirth: Yup.date().nullable(),
          address: Yup.string(),
          groups: Yup.array()
            .of(Yup.string())
            .min(1, 'Insira ao menos um grupo'),
        });

        await schema.validate(data, { abortEarly: false });

        const { password, address, dateOfBirth } = data;

        if (!password) {
          delete data.password;
          delete data.passwordConfirm;
        }
        if (!address) delete data.address;
        if (!dateOfBirth) delete data.dateOfBirth;

        await api.put(`/students/${studentToUpdate?._id}`, data, {
          headers: {
            Authorization,
          },
        });

        const currentStudents = students;
        const studentIdx = currentStudents.findIndex(
          (s) => s._id === studentToUpdate?._id,
        );

        if (data.groups) {
          currentStudents[studentIdx] = {
            ...currentStudents[studentIdx],
            ...data,
            groups: data?.groups.map((groupId: string) => {
              const foundGroup: Group | undefined = groups.find(
                (g) => g._id === groupId,
              );

              return {
                _id: groupId,
                name: foundGroup ? foundGroup.name : '',
                createdAt: new Date(),
                updatedAt: new Date(),
                __v: '0',
              };
            }),
          };
        }

        setStudents([...currentStudents]);

        setAlert({
          isActive: true,
          type: 'success',
          message: 'Aluno atualizado com sucesso.',
        });

        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRefUpdate.current?.setErrors(errors);
        } else if (error.message.includes('400')) {
          setAlert({
            isActive: true,
            type: 'warning',
            message: 'E-mail já utilizado.',
          });
        } else {
          setAlert({
            isActive: true,
            type: 'error',
            message: 'Erro ao atualizar aluno.',
          });
        }
      }
    },
    [students, studentToUpdate, groups],
  );

  const handleAlert = useCallback(() => {
    setAlert({ isActive: false });
  }, []);

  return (
    <Container>
      <Button disabled>
        <IconButton>
          <PersonAdd />
        </IconButton>
        <h2>Alunos</h2>
      </Button>

      <div style={{ alignSelf: 'center' }}>
        <IconButton onClick={() => setOpen(true)}>
          <Add style={{ color: green[500] }} fontSize="large" />
        </IconButton>
      </div>

      <DialogForm
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        title="Criar Aluno"
        formRef={formRefCreate}
      >
        <div className="row">
          <Input
            name="firstName"
            type="text"
            label="Nome"
            size={50}
            placeholder="Digite o primeiro nome"
          />

          <Input
            name="lastName"
            type="text"
            label="Sobrenome"
            size={50}
            placeholder="Digite o sobrenome"
          />
        </div>
        <div className="row">
          <Input
            name="email"
            type="email"
            label="E-mail"
            size={50}
            placeholder="Digite um e-mail"
          />

          <Input
            name="password"
            type="password"
            label="Senha"
            size={50}
            placeholder="Digite uma senha"
          />
        </div>

        <div className="row">
          <Input
            name="address"
            type="text"
            label="Endereço (opcional)"
            size={50}
            placeholder="Digite o endereço"
          />

          <DatePicker
            name="dateOfBirth"
            label="Data de nascimento (opcional)"
          />
        </div>

        <div className="row">
          <Checkbox
            name="groups"
            title="Grupos"
            color="primary"
            options={groups.map((group) => {
              return {
                _id: group._id,
                value: group._id,
                label: group.name,
              };
            })}
          />
        </div>
      </DialogForm>

      <DialogForm
        open={!!studentToUpdate}
        handleClose={handleClose}
        handleSubmit={handleUpdateSubmit}
        title="Atualizar Aluno"
        formRef={formRefUpdate}
        initialData={studentToUpdate}
      >
        <div className="row">
          <Input
            name="firstName"
            type="text"
            label="Nome"
            size={50}
            placeholder="Digite o primeiro nome"
          />

          <Input
            name="lastName"
            type="text"
            label="Sobrenome"
            size={50}
            placeholder="Digite o sobrenome"
          />
        </div>
        <div className="row">
          <Input
            name="email"
            type="email"
            label="E-mail"
            size={50}
            placeholder="Digite um e-mail"
          />

          <Input
            name="password"
            type="password"
            label="Senha"
            size={50}
            placeholder="Digite uma senha"
          />

          <Input
            name="passwordConfirm"
            type="password"
            label="Confirmar Senha"
            size={50}
            placeholder="Confirme a senha"
          />
        </div>

        <div className="row">
          <Input
            name="address"
            type="text"
            label="Endereço (opcional)"
            size={50}
            placeholder="Digite o endereço"
          />

          <DatePicker
            name="dateOfBirth"
            label="Data de nascimento (opcional)"
          />
        </div>

        <div className="row">
          <Checkbox
            name="groups"
            title="Grupos"
            color="primary"
            options={groups.map((group) => {
              return {
                _id: group._id,
                value: group._id,
                label: group.name,
              };
            })}
          />
        </div>
      </DialogForm>

      <div className="cards-container">
        {students?.map((student) => (
          <div className="student-card">
            <Card key={student._id} className={classes.root}>
              <CardHeader
                avatar={
                  <Avatar className={classes.avatar}>
                    {`${student.firstName
                      .split('')[0]
                      .toUpperCase()}${student.lastName
                      .split('')[0]
                      .toUpperCase()}`}
                  </Avatar>
                }
                action={
                  <Tooltip title="Deletar">
                    <IconButton>
                      <Delete
                        onClick={() => handleDelete(student._id)}
                        color="error"
                      />
                    </IconButton>
                  </Tooltip>
                }
                title={`${student.firstName} ${student.lastName}`}
                subheader={`Criado em ${moment(student.createdAt).format(
                  'DD/MM/YYYY',
                )}`}
              />

              <CardContent>
                <Typography color="textPrimary" component="p">
                  {student.email}
                </Typography>

                {student.dateOfBirth && (
                  <Typography color="textSecondary" component="p">
                    {`${moment().diff(
                      moment(student.dateOfBirth).format('YYYY-MM-DD'),
                      'years',
                    )} Anos`}

                    {student.address && (
                      <Typography color="textSecondary" component="p">
                        {`${student.address}`}
                      </Typography>
                    )}
                  </Typography>
                )}
              </CardContent>

              <CardActions disableSpacing className="card-actions">
                <div className="student-groups">
                  {student.groups.map((group) => (
                    <Chip label={group.name} variant="outlined" />
                  ))}
                </div>
                <Tooltip title="Editar">
                  <Button onClick={() => handleOpenUpdate(student._id)}>
                    <Edit color="primary" />
                  </Button>
                </Tooltip>
              </CardActions>
            </Card>
          </div>
        ))}
      </div>

      {alert.isActive && (
        <Alert onClose={handleAlert} type={alert.type || 'success'}>
          {alert.message}
        </Alert>
      )}
    </Container>
  );
};

export default Students;
