import {
  PersonAdd,
  VerifiedUser,
  SupervisorAccount,
  AssignmentReturn,
  Assignment,
  GroupAdd,
  MeetingRoom,
  Archive,
} from '@material-ui/icons';

export default [
  {
    name: 'Admins',
    Icon: VerifiedUser,
    link: '/',
  },
  {
    name: 'Coordenadores',
    Icon: SupervisorAccount,
    link: '/coordenadores',
  },
  {
    name: 'Grupos',
    Icon: GroupAdd,
    link: '/grupos',
  },
  {
    name: 'Alunos',
    Icon: PersonAdd,
    link: '/alunos',
  },
  {
    name: 'Salas',
    Icon: MeetingRoom,
    link: '/salas',
  },
  {
    name: 'Inscrição anual',
    Icon: Assignment,
    link: '/inscricoes',
  },
  {
    name: 'Preceptorias',
    Icon: AssignmentReturn,
    link: '/preceptorias',
  },
  {
    name: 'Relatórios',
    Icon: Archive,
    link: '/relatorios',
  },
];
