import React, { useEffect, useRef, InputHTMLAttributes } from 'react';
import { useField } from '@unform/core';

import {
  Checkbox,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@material-ui/core';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  title: string;
  color?: 'primary' | 'secondary' | 'default' | undefined;
  options: {
    _id: string;
    value: string;
    label: string;
  }[];
}

const CheckboxInput: React.FC<Props> = ({
  name,
  title,
  color,
  options,
  ...rest
}) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, registerField, defaultValue = [], error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {
        return refs.filter((ref) => ref.checked).map((ref) => ref.value);
      },
      clearValue: (refs: HTMLInputElement[]) => {
        refs.forEach((ref) => {
          ref.checked = false;
        });
      },
      setValue: (refs: HTMLInputElement[], values: string[]) => {
        refs.forEach((ref) => {
          if (values.includes(ref.id)) {
            ref.checked = true;
          }
        });
      },
    });
  }, [defaultValue, fieldName, registerField]);

  return (
    <FormControl error={!!error}>
      <FormLabel component="legend">{title}</FormLabel>

      {error && <FormLabel component="legend">{error}</FormLabel>}

      <div style={{ display: 'flex' }}>
        {options.map((option, index) => (
          <FormControlLabel
            key={option._id}
            control={
              <Checkbox
                inputProps={{
                  defaultChecked: defaultValue.find(
                    (dv: string) => dv === option._id,
                  ),
                  value: option.value,
                  type: 'checkbox',
                  id: option._id,
                  ...rest,
                }}
                inputRef={(ref) => {
                  inputRefs.current[index] = ref as HTMLInputElement;
                }}
                name={option.value}
                color={color}
              />
            }
            label={option.label}
          />
        ))}
      </div>
    </FormControl>
  );
};

export default CheckboxInput;
