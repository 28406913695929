import styled from 'styled-components';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media (max-width: 650px) {
      width: 100%;
      flex-direction: column;
      align-items: center;
    }
  }

  .registration-card {
    margin: 1%;
    width: 25%;

    @media (max-width: 1067px) {
      width: 45%;
    }
    @media (max-width: 650px) {
      width: 100%;
    }
  }
`;

export const GroupFormContainer = styled.div`
  display: flex;
  flex-direction: column;

  .label {
    margin-top: 50px;
  }
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 345,
    },
    media: {
      height: 0,
      paddingTop: '56.25%',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: '#4472CA',
    },
  }),
);
