import React, { useCallback, useState, useRef } from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';

import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  MenuItem,
  Menu,
  CssBaseline,
  IconButton,
  useTheme,
} from '@material-ui/core';

import {
  Menu as MenuIcon,
  ChevronLeft,
  ChevronRight,
  AccountCircle,
} from '@material-ui/icons';

import { Switch, useRouteMatch } from 'react-router-dom';

import { useAuth } from '../../hooks/Auth';
import MenuItens from './menuItens';
import Logo from '../../assets/Ceapia-02.png';
import Route from '../../routes/Route';

import Groups from '../Groups';
import Admins from '../Admins';
import Students from '../Students';
import Coordinators from '../Coordinators';
import Rooms from '../Rooms';
import AnnualRegistrations from '../AnnualRegistration';
import Preceptorships from '../Preceptorships';
import Reports from '../Report';

import Input from '../../components/Input';
import Alert from '../../components/Alert';
import DialogForm from '../../components/DialogForm';

import { UpdateAdminData } from './dtos';
import { Container, Header, useStyles, StyledLink } from './styles';

import api from '../../services/api';

import { AlertProps } from '../../utils/dtos';
import { Admin } from '../Admins/dtos';
import getValidationErrors from '../../utils/getValidationErrors';

const Dashboard: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const formRefUpdate = useRef<FormHandles>(null);
  const [myDataOpen, setMyDataOpen] = useState(false);
  const [admin, setAdmin] = useState<Admin>({} as Admin);
  const [alert, setAlert] = useState<AlertProps>({
    isActive: false,
  });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const { logout, user, token } = useAuth();
  const { path } = useRouteMatch();

  const Authorization = `Bearer ${token}`;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAlert = useCallback(() => {
    setAlert({ isActive: false });
  }, []);

  const handleMyDataOpen = useCallback(async () => {
    try {
      const response = await api.get(`/admins/me`, {
        headers: {
          Authorization,
        },
      });

      setAdmin(response.data);
      setMyDataOpen(true);
    } catch (error) {
      setAlert({
        isActive: true,
        type: 'error',
        message: 'Erro ao buscar dados do perfil.',
      });
    } finally {
      handleClose();
    }
  }, []);

  const handleMyDataClose = () => {
    setMyDataOpen(false);
  };

  const handleSubmit = useCallback(async (data: UpdateAdminData) => {
    try {
      formRefUpdate.current?.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        email: Yup.string()
          .email('Digite um e-mail válido.')
          .required('E-mail obrigatório.'),
        password: Yup.string(),
        passwordConfirm: Yup.string().oneOf(
          [Yup.ref('password')],
          'Senhas devem ser iguais.',
        ),
      });

      await schema.validate(data, { abortEarly: false });

      const { password } = data;

      if (!password) {
        delete data.password;
        delete data.passwordConfirm;
      }

      await api.put(`/admins`, data, {
        headers: {
          Authorization,
        },
      });

      setAlert({
        isActive: true,
        type: 'success',
        message: 'Perfil atualizado com sucesso.',
      });

      handleMyDataClose();
      window.location.reload(false);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);

        formRefUpdate.current?.setErrors(errors);
      } else {
        setAlert({
          isActive: true,
          type: 'error',
          message: 'Erro ao atualizar perfil.',
        });
      }
    }
  }, []);

  return (
    <Container>
      <DialogForm
        open={myDataOpen}
        handleClose={handleMyDataClose}
        handleSubmit={handleSubmit}
        title="Editar Perfil"
        formRef={formRefUpdate}
        initialData={admin}
      >
        <Input
          name="name"
          type="text"
          label="Nome"
          placeholder="Digite um nome"
        />

        <Input
          name="email"
          type="email"
          label="E-mail"
          placeholder="Digite um e-mail"
        />

        <Input
          name="password"
          type="password"
          label="Nova Senha"
          placeholder="Digite uma nova senha (opcional)"
        />

        <Input
          name="passwordConfirm"
          type="password"
          label="Confirmar Senha"
          placeholder="Confirme a nova senha (opcional)"
        />
      </DialogForm>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar className={classes.toolbar}>
            <div className={classes.toolbar}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <MenuIcon />
              </IconButton>

              <Typography noWrap>Sistema de Preceptoria</Typography>
            </div>

            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle fontSize="large" />
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                keepMounted
                open={openMenu}
                onClose={handleClose}
                className={classes.menuAccount}
              >
                <MenuItem onClick={handleMyDataOpen}>Meus dados</MenuItem>
                <MenuItem onClick={logout}>Sair</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
            </IconButton>
          </div>

          <Divider />

          <List>
            {MenuItens.slice(0, 5).map((item) => (
              <StyledLink to={`${path}${item.link}`}>
                <ListItem button key={item.name}>
                  <ListItemIcon>
                    <Tooltip title={item.name} placement="right">
                      <item.Icon />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItem>
              </StyledLink>
            ))}

            <Divider />

            {MenuItens.slice(5).map((item) => (
              <StyledLink to={`${path}${item.link}`}>
                <ListItem button key={item.name}>
                  <ListItemIcon>
                    <Tooltip title={item.name} placement="right">
                      <item.Icon />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItem>
              </StyledLink>
            ))}
          </List>
        </Drawer>

        <main className={classes.content}>
          <div className={classes.toolbar} />

          <Header>
            <div>
              <h3>{`Bem-vindo, ${user.name}`}</h3>
              <span>{user.email}</span>
            </div>

            <img src={Logo} alt="logo" />
          </Header>

          <Switch>
            <Route exact path={`${path}`} component={Admins} isPrivate />
            <Route path={`${path}/grupos`} component={Groups} isPrivate />
            <Route
              exact
              path={`${path}/coordenadores`}
              component={Coordinators}
              isPrivate
            />
            <Route
              exact
              path={`${path}/alunos`}
              component={Students}
              isPrivate
            />
            <Route exact path={`${path}/salas`} component={Rooms} isPrivate />
            <Route
              exact
              path={`${path}/inscricoes`}
              component={AnnualRegistrations}
              isPrivate
            />
            <Route
              exact
              path={`${path}/preceptorias`}
              component={Preceptorships}
              isPrivate
            />
            <Route
              exact
              path={`${path}/relatorios`}
              component={Reports}
              isPrivate
            />
          </Switch>
        </main>
      </div>
      );
      {alert.isActive && (
        <Alert onClose={handleAlert} type={alert.type || 'success'}>
          {alert.message}
        </Alert>
      )}
    </Container>
  );
};

export default Dashboard;
