import React, { useState, useCallback, useRef, useEffect } from 'react';
import { FormHandles, Scope } from '@unform/core';
import * as Yup from 'yup';
import moment from 'moment';

import { Delete, Add, AssignmentReturn, Edit } from '@material-ui/icons';

import {
  Button,
  Typography,
  IconButton,
  CardActions,
  CardContent,
  CardHeader,
  Card,
  Chip,
  Switch,
  Tooltip,
  Divider,
  FormLabel,
} from '@material-ui/core';

import { green } from '@material-ui/core/colors';

import {
  Container,
  useStyles,
  GroupFormContainer,
  CustomSelect,
} from './styles';

import getValidationErrors from '../../utils/getValidationErrors';

import api from '../../services/api';

import Alert from '../../components/Alert';
import Input from '../../components/Input';
import DialogForm from '../../components/DialogForm';
import DatePicker from '../../components/DatePicker';

import { useAuth } from '../../hooks/Auth';

import { AlertProps } from '../../utils/dtos';
import errorMessages from '../../utils/translatedResponseMessages';

import { Group } from '../Groups/dtos';
import { Room } from '../Rooms/dtos';
import { Coordinator } from '../Coordinators/dtos';
import { AnnualRegistration } from '../AnnualRegistration/dtos';

import {
  CreatePreceptorshipData,
  Preceptorship,
  CreateVacancy,
  UpdatePreceptorshipData,
} from './dtos';

interface Weekdays {
  [key: number]: string;
}

const weekDays: Weekdays = {
  1: 'Segunda',
  2: 'Terça',
  3: 'Quarta',
  4: 'Quinta',
  5: 'Sexta',
  6: 'Sábado',
  0: 'Domingo',
};

const Rooms: React.FC = () => {
  const classes = useStyles();
  const { token } = useAuth();
  const formRefCreate = useRef<FormHandles>(null);
  const formRefUpdate = useRef<FormHandles>(null);

  const [open, setOpen] = useState(false);
  const [vacancyError, setVacancyError] = useState('');

  const [preceptorships, setPreceptorships] = useState<Preceptorship[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [rooms, setRooms] = useState<Room[]>([]);
  const [registrations, setRegistrations] = useState<AnnualRegistration[]>([]);
  const [coordinators, setCoordinators] = useState<Coordinator[]>([]);

  const [
    precepToUpdate,
    setPrecepToUpdate,
  ] = useState<UpdatePreceptorshipData | null>(null);
  const [alert, setAlert] = useState<AlertProps>({
    isActive: false,
  });

  const Authorization = `Bearer ${token}`;

  useEffect(() => {
    async function getPreceptorships() {
      try {
        const preceptorshipResponse = await api.get('/preceptorships', {
          headers: {
            Authorization,
          },
        });

        setPreceptorships(preceptorshipResponse.data);

        setAlert({
          isActive: true,
          type: 'success',
          message: 'Preceptorias listadas com sucesso.',
        });
      } catch (error) {
        if (error.message.includes('401')) {
          setAlert({
            isActive: true,
            type: 'warning',
            message: 'Sessão expirada, faça login novamente.',
          });
        } else {
          setAlert({
            isActive: true,
            type: 'error',
            message: 'Erro ao listar preceptorias.',
          });
        }
      }
    }

    getPreceptorships();
  }, []);

  const handleDelete = useCallback(
    async (preceptorshipId) => {
      try {
        await api.delete(`/preceptorships/${preceptorshipId}`, {
          headers: {
            Authorization,
          },
        });

        setAlert({
          isActive: true,
          type: 'success',
          message: 'Preceptoria deletada com sucesso.',
        });

        if (preceptorships?.length) {
          const newPreceptorships = preceptorships.filter(
            (preceptorship) => preceptorship._id !== preceptorshipId,
          );

          setPreceptorships(newPreceptorships);
        }
      } catch (error) {
        setAlert({
          isActive: true,
          type: 'error',
          message: 'Erro ao deletar preceptoria.',
        });
      }
    },
    [preceptorships],
  );

  const handleActivate = useCallback(
    async (precepId) => {
      try {
        await api.get(`/preceptorships/${precepId}/activateDeactivate`, {
          headers: {
            Authorization,
          },
        });

        const currentPreceps = preceptorships;
        const precepIdx = currentPreceps.findIndex((r) => r._id === precepId);
        currentPreceps[precepIdx].isActive = !currentPreceps[precepIdx]
          .isActive;

        setPreceptorships([...currentPreceps]);
      } catch (error) {
        setAlert({
          isActive: true,
          type: 'error',
          message: 'Erro ao ativar / desativar preceptoria.',
        });
      }
    },
    [preceptorships],
  );

  const handleOpenUpdate = useCallback(
    async (preceptorshipId) => {
      try {
        if (!registrations.length) {
          const annualRegistrations = await api.get('/annualRegistrations', {
            headers: {
              Authorization,
            },
          });

          setRegistrations(annualRegistrations.data);
        }

        if (!groups.length) {
          const groupsResponse = await api.get('/groups', {
            headers: {
              Authorization,
            },
          });

          setGroups(groupsResponse.data);
        }

        if (!coordinators.length) {
          const coordinatorsResponse = await api.get('/coordinators', {
            headers: {
              Authorization,
            },
          });

          setCoordinators(coordinatorsResponse.data);
        }

        if (!rooms.length) {
          const roomsResponse = await api.get('/rooms', {
            headers: {
              Authorization,
            },
          });

          setRooms(roomsResponse.data);
        }

        const currentPreceps = preceptorships;
        const findIdx = currentPreceps.findIndex(
          (p) => p._id === preceptorshipId,
        );

        const precepToUp = currentPreceps[findIdx];

        const updatePrecep: UpdatePreceptorshipData = {
          _id: precepToUp._id,
          order: precepToUp.order,
          totalStudents: precepToUp.totalStudents,
          vacancy: [],
          startTime: new Date(precepToUp.startTime),
          endTime: new Date(precepToUp.endTime),
          annualRegistration: {
            value: precepToUp.annualRegistration._id,
            label: precepToUp.annualRegistration.name,
          },
          room: {
            value: precepToUp.room._id,
            label: precepToUp.room.name,
          },
          coordinators: precepToUp.coordinators.map((c) => ({
            value: c._id,
            label: c.name,
          })),
        };

        updatePrecep.vacancy = precepToUp.vacancy.map((v) => ({
          group: v.group.name,
          placesLeft: v.placesLeft,
        }));

        setPrecepToUpdate(updatePrecep);

        setAlert({
          isActive: true,
          type: 'success',
          message: 'Dados carregados com sucesso.',
        });
      } catch (error) {
        setAlert({
          isActive: true,
          type: 'error',
          message: 'Erro ao buscar dados.',
        });
      }
    },
    [preceptorships],
  );

  const handleOpen = useCallback(async () => {
    try {
      if (!registrations.length) {
        const annualRegistrations = await api.get('/annualRegistrations', {
          headers: {
            Authorization,
          },
        });

        setRegistrations(annualRegistrations.data);
      }

      if (!groups.length) {
        const groupsResponse = await api.get('/groups', {
          headers: {
            Authorization,
          },
        });

        setGroups(groupsResponse.data);
      }

      if (!coordinators.length) {
        const coordinatorsResponse = await api.get('/coordinators', {
          headers: {
            Authorization,
          },
        });

        setCoordinators(coordinatorsResponse.data);
      }

      if (!rooms.length) {
        const roomsResponse = await api.get('/rooms', {
          headers: {
            Authorization,
          },
        });

        setRooms(roomsResponse.data);
      }

      setAlert({
        isActive: true,
        type: 'success',
        message: 'Dados carregados com sucesso.',
      });
    } catch (error) {
      if (error.message.includes('401')) {
        setAlert({
          isActive: true,
          type: 'warning',
          message: 'Sessão expirada, faça login novamente.',
        });
      } else {
        setAlert({
          isActive: true,
          type: 'error',
          message: 'Erro ao buscar dados.',
        });
      }
    }

    setOpen(true);
  }, [registrations, rooms, groups, coordinators]);

  const handleClose = useCallback(() => {
    setOpen(false);
    setPrecepToUpdate(null);
  }, []);

  const handleSubmit = useCallback(
    async (data: CreatePreceptorshipData) => {
      try {
        formRefCreate.current?.setErrors({});
        setVacancyError('');

        data.vacancy = data.vacancy.filter((v) => Number(v.placesLeft) > 0);

        const schema = Yup.object().shape({
          annualRegistration: Yup.string().required(
            'Inscrição anual obrigatória',
          ),
          startTime: Yup.date().typeError(
            'Data / horário de início obrigatório',
          ),
          endTime: Yup.date()
            .typeError('Data / horário de término obrigatório')
            .min(
              Yup.ref('startTime'),
              'Data de término deve ser após o início',
            ),
          room: Yup.string().required('Sala obrigatória'),
          order: Yup.number()
            .typeError('Digite um número válido')
            .min(1, 'Número de ordem não permitido')
            .required('Ordem obrigatória'),
          totalStudents: Yup.number()
            .typeError('Digite um número válido')
            .min(1, 'Número de ordem não permitido')
            .required('Total de vagas obrigatório'),
          coordinators: Yup.array()
            .of(Yup.string())
            .min(2, 'Selecione pelo menos 2 coordenadores')
            .required('Coordenadores obrigratórios'),
          vacancy: Yup.array()
            .of(
              Yup.object().shape({
                group: Yup.string().required(),
                placesLeft: Yup.number()
                  .min(0, 'Digite um número de vagas válido')
                  .required('Número de vagas obrigatório'),
              }),
            )
            .required('Configure ao menos uma vaga'),
        });

        await schema.validate(data, { abortEarly: false });

        data.vacancy = data.vacancy.map((v: CreateVacancy) => {
          const group: Group | undefined = groups.find(
            (g) => g.name === v.group,
          );

          return {
            placesLeft: v.placesLeft,
            group: group ? group._id : 'asd',
          };
        });

        const response = await api.post(`/preceptorships`, data, {
          headers: {
            Authorization,
          },
        });

        const currentPreceptorships = preceptorships;

        response.data.coordinators = response.data.coordinators.map(
          (coordinatorId: string) => {
            const coordinator: Coordinator | undefined = coordinators.find(
              (c: Coordinator) => c._id === coordinatorId,
            );

            return {
              _id: coordinatorId,
              name: coordinator ? coordinator.name : 'asd',
            };
          },
        );

        const registration: AnnualRegistration | undefined = registrations.find(
          (r: AnnualRegistration) => r._id === response.data.annualRegistration,
        );

        response.data.annualRegistration = registration
          ? { _id: registration._id, name: registration.name }
          : {};

        const room: Room | undefined = rooms.find(
          (r: Room) => r._id === response.data.room,
        );

        response.data.room = room ? { _id: room._id, name: room.name } : {};

        currentPreceptorships?.push(response.data);

        setPreceptorships(currentPreceptorships);

        setAlert({
          isActive: true,
          type: 'success',
          message: 'Preceptoria cadastrada com sucesso.',
        });

        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRefCreate.current?.setErrors(errors);

          if (formRefCreate.current?.getErrors().vacancy)
            setVacancyError(formRefCreate.current?.getErrors().vacancy || '');
        } else if (error.response.status === 400) {
          setAlert({
            isActive: true,
            type: 'warning',
            message:
              errorMessages[error.response.data.message] ||
              errorMessages.Default,
          });
        } else {
          setAlert({
            isActive: true,
            type: 'error',
            message: 'Erro ao criar preceptoria.',
          });
        }
      }
    },
    [preceptorships, groups, coordinators, rooms, registrations],
  );

  const handleUpdateSubmit = useCallback(
    async (data: CreatePreceptorshipData) => {
      try {
        formRefUpdate.current?.setErrors({});
        setVacancyError('');

        data.vacancy = data.vacancy.filter((v) => Number(v.placesLeft) > 0);

        const schema = Yup.object().shape({
          annualRegistration: Yup.string().required(
            'Inscrição anual obrigatória',
          ),
          startTime: Yup.date().typeError(
            'Data / horário de início obrigatório',
          ),
          endTime: Yup.date()
            .typeError('Data / horário de término obrigatório')
            .min(
              Yup.ref('startTime'),
              'Data de término deve ser após o início',
            ),
          room: Yup.string().required('Sala obrigatória'),
          order: Yup.number()
            .typeError('Digite um número válido')
            .min(1, 'Número de ordem não permitido')
            .required('Ordem obrigatória'),
          totalStudents: Yup.number()
            .typeError('Digite um número válido')
            .min(1, 'Número de ordem não permitido')
            .required('Total de vagas obrigatório'),
          coordinators: Yup.array()
            .of(Yup.string())
            .min(2, 'Selecione pelo menos 2 coordenadores')
            .required('Coordenadores obrigratórios'),
          vacancy: Yup.array()
            .of(
              Yup.object().shape({
                group: Yup.string().required(),
                placesLeft: Yup.number()
                  .min(0, 'Digite um número de vagas válido')
                  .required('Número de vagas obrigatório'),
              }),
            )
            .required('Configure ao menos uma vaga'),
        });

        await schema.validate(data, { abortEarly: false });

        data.vacancy = data.vacancy.map((v: CreateVacancy) => {
          const group: Group | undefined = groups.find(
            (g) => g.name === v.group,
          );

          return {
            placesLeft: v.placesLeft,
            group: group ? group._id : 'asd',
          };
        });

        await api.put(`/preceptorships/${precepToUpdate?._id}`, data, {
          headers: {
            Authorization,
          },
        });

        const currentPreceptorships = preceptorships;
        const findIdx = currentPreceptorships.findIndex(
          (p) => p._id === precepToUpdate?._id,
        );

        currentPreceptorships[findIdx].coordinators = data.coordinators.map(
          (coordinatorId: string) => {
            const coordinator: Coordinator | undefined = coordinators.find(
              (c: Coordinator) => c._id === coordinatorId,
            );

            return {
              _id: coordinatorId,
              name: coordinator ? coordinator.name : 'asd',
            };
          },
        );

        const registration: AnnualRegistration | undefined = registrations.find(
          (r: AnnualRegistration) => r._id === data.annualRegistration,
        );

        if (registration) {
          currentPreceptorships[findIdx].annualRegistration = {
            _id: registration._id,
            name: registration.name,
          };
        }

        const room: Room | undefined = rooms.find(
          (r: Room) => r._id === data.room,
        );

        if (room) {
          currentPreceptorships[findIdx].room = {
            _id: room._id,
            name: room.name,
          };
        }

        currentPreceptorships[findIdx].totalStudents = data.totalStudents;

        if (data.vacancy) {
          currentPreceptorships[findIdx].vacancy = data.vacancy.map(
            (vacancy: CreateVacancy) => {
              const group: Group | undefined = groups.find(
                (g: Group) => g._id === vacancy.group,
              );

              return {
                _id: group?._id || 'asd',
                group: {
                  _id: group?._id || 'asd',
                  name: group?.name || 'asd',
                },
                placesLeft: vacancy.placesLeft || 0,
              };
            },
          );
        }

        setPreceptorships([...currentPreceptorships]);

        setAlert({
          isActive: true,
          type: 'success',
          message: 'Preceptoria atualizada com sucesso.',
        });

        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRefUpdate.current?.setErrors(errors);

          if (formRefUpdate.current?.getErrors().vacancy)
            setVacancyError(formRefUpdate.current?.getErrors().vacancy || '');
        } else if (error.response.status === 400) {
          setAlert({
            isActive: true,
            type: 'warning',
            message:
              errorMessages[error.response.data.message] ||
              errorMessages.Default,
          });
        } else {
          setAlert({
            isActive: true,
            type: 'error',
            message: 'Erro ao atualizar preceptoria.',
          });
        }
      }
    },
    [
      preceptorships,
      groups,
      coordinators,
      rooms,
      registrations,
      precepToUpdate,
    ],
  );

  const handleAlert = useCallback(() => {
    setAlert({ isActive: false });
  }, []);

  return (
    <Container>
      <Button disabled>
        <IconButton>
          <AssignmentReturn />
        </IconButton>
        <h2>Preceptorias</h2>
      </Button>

      <div style={{ alignSelf: 'center' }}>
        <IconButton onClick={() => handleOpen()}>
          <Add style={{ color: green[500] }} fontSize="large" />
        </IconButton>
      </div>

      <DialogForm
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        title="Criar Preceptoria"
        formRef={formRefCreate}
      >
        <div className="row">
          <DatePicker name="startTime" label="Início" />

          <DatePicker name="endTime" label="Término" />

          <Input name="order" type="number" label="Ordem" defaultValue={0} />

          <Input
            name="totalStudents"
            type="number"
            label="Total de vagas"
            defaultValue={1}
          />
        </div>

        <div className="row">
          <CustomSelect
            name="annualRegistration"
            options={registrations
              .filter((r: AnnualRegistration) => r.isActive)
              .map((registration) => ({
                value: registration._id,
                label: registration.name,
              }))}
            placeholder="Selecione a inscrição anual"
            noOptionsMessage={() => 'Sem opções restantes'}
          />

          <CustomSelect
            name="room"
            options={rooms
              .filter((room) => room.isActive)
              .map((room) => ({ value: room._id, label: room.name }))}
            placeholder="Selecione a sala"
            noOptionsMessage={() => 'Sem opções restantes'}
          />
        </div>

        <div className="row">
          <CustomSelect
            isMulti
            name="coordinators"
            placeholder="Selecione os coordenadores"
            options={coordinators
              .filter((coordinator) => coordinator.isActive)
              .map((coordinator) => ({
                value: coordinator._id,
                label: coordinator.name,
              }))}
            noOptionsMessage={() => 'Sem opções restantes'}
          />
        </div>

        <GroupFormContainer>
          <div className="row">
            <FormLabel className="label">Vagas por grupo</FormLabel>
          </div>

          {!!vacancyError.length && (
            <div className="row">
              <FormLabel
                error
                style={{ fontSize: '0.75rem', marginLeft: '2px' }}
              >
                {vacancyError}
              </FormLabel>
            </div>
          )}

          {groups?.map((group, index) => (
            <div className="row">
              <Scope path={`vacancy[${index}]`}>
                <Input
                  name="group"
                  type="text"
                  label="Grupo"
                  defaultValue={group.name}
                  readOnly
                />

                <Input
                  name="placesLeft"
                  type="number"
                  label="Número de vagas"
                  defaultValue={0}
                />
              </Scope>
            </div>
          ))}
        </GroupFormContainer>

        <Divider />
      </DialogForm>

      <DialogForm
        open={!!precepToUpdate}
        handleClose={handleClose}
        handleSubmit={handleUpdateSubmit}
        title="Atualizar Preceptoria"
        formRef={formRefUpdate}
        initialData={precepToUpdate}
      >
        <div className="row">
          <DatePicker name="startTime" label="Início" />

          <DatePicker name="endTime" label="Término" />

          <Input name="order" type="number" label="Ordem" />

          <Input name="totalStudents" type="number" label="Total de vagas" />
        </div>

        <div className="row">
          <CustomSelect
            name="annualRegistration"
            options={registrations
              .filter((r: AnnualRegistration) => r.isActive)
              .map((registration) => ({
                key: registration._id,
                value: registration._id,
                label: registration.name,
              }))}
            placeholder="Selecione a inscrição anual"
            noOptionsMessage={() => 'Sem opções restantes'}
          />

          <CustomSelect
            name="room"
            options={rooms
              .filter((room) => room.isActive)
              .map((room) => ({
                key: room._id,
                value: room._id,
                label: room.name,
              }))}
            placeholder="Selecione a sala"
            noOptionsMessage={() => 'Sem opções restantes'}
          />
        </div>

        <div className="row">
          <CustomSelect
            isMulti
            name="coordinators"
            placeholder="Selecione os coordenadores"
            options={coordinators
              .filter((coordinator) => coordinator.isActive)
              .map((coordinator) => ({
                key: coordinator._id,
                value: coordinator._id,
                label: coordinator.name,
              }))}
            noOptionsMessage={() => 'Sem opções restantes'}
          />
        </div>

        <GroupFormContainer>
          <div className="row">
            <FormLabel className="label">Vagas por grupo</FormLabel>
          </div>

          {!!vacancyError.length && (
            <div className="row">
              <FormLabel
                error
                style={{ fontSize: '0.75rem', marginLeft: '2px' }}
              >
                {vacancyError}
              </FormLabel>
            </div>
          )}

          {groups?.map((group, index) => (
            <div className="row">
              <Scope path={`vacancy[${index}]`}>
                <Input
                  name="group"
                  type="text"
                  label="Grupo"
                  defaultValue={group.name}
                  readOnly
                />

                <Input
                  name="placesLeft"
                  type="number"
                  label="Número de vagas"
                />
              </Scope>
            </div>
          ))}
        </GroupFormContainer>

        <Divider />
      </DialogForm>

      <div className="cards-container">
        {preceptorships?.map((preceptorship) => (
          <div className="preceptorship-card">
            <Card key={preceptorship._id} className={classes.root}>
              <CardHeader
                action={(
                  <Tooltip title="Deletar">
                    <IconButton>
                      <Delete
                        onClick={() => handleDelete(preceptorship._id)}
                        color="error"
                      />
                    </IconButton>
                  </Tooltip>
                )}
                title={weekDays[moment(preceptorship.startTime).weekday()]}
                subheader={`${moment(preceptorship.startTime).format(
                  'HH:mm',
                )} às ${moment(preceptorship.endTime).format('HH:mm')}`}
              />

              <CardContent>
                <Typography align="center">
                  <div className="student-groups">
                    {preceptorship.coordinators.map((coordinator) => (
                      <Chip
                        key={coordinator._id}
                        label={coordinator.name}
                        variant="outlined"
                        color="primary"
                        style={{ margin: '2px', fontWeight: 'bolder' }}
                      />
                    ))}
                  </div>
                </Typography>
              </CardContent>

              <CardActions
                disableSpacing
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Typography>
                  <Chip
                    label={preceptorship.annualRegistration.name}
                    variant="outlined"
                    color="default"
                    style={{ margin: '2px', fontWeight: 'bolder' }}
                  />
                </Typography>

                <Typography>
                  <Chip
                    label={`${preceptorship.totalStudents} vagas`}
                    variant="outlined"
                    color="default"
                    style={{ margin: '2px', fontWeight: 'bolder' }}
                  />
                </Typography>

                <div style={{ display: 'flex' }}>
                  <Tooltip
                    title={`${preceptorship.isActive ? 'Ativo' : 'Inativo'}`}
                  >
                    <Switch
                      checked={preceptorship.isActive}
                      onClick={() => handleActivate(preceptorship._id)}
                      name="isActive"
                    />
                  </Tooltip>

                  <Tooltip title="Editar">
                    <Button onClick={() => handleOpenUpdate(preceptorship._id)}>
                      <Edit color="primary" />
                    </Button>
                  </Tooltip>
                </div>
              </CardActions>
            </Card>
          </div>
        ))}
      </div>

      {alert.isActive && (
        <Alert onClose={handleAlert} type={alert.type || 'success'}>
          {alert.message}
        </Alert>
      )}
    </Container>
  );
};

export default Rooms;
